import {
  FaFacebookF,
  FaTwitter,
  FaPinterest,
  FaInstagram,
} from 'react-icons/fa';

const data = [
  {
    title: 'About Us',
    links: [
      {
        text: 'About Us',
        href: '/page/about-us',
      },
      // {
      //   text: 'All Reviews',
      // },
      {
        text: 'Contact Us',
        href: '/contact-us',
      },
    ],
  },
  {
    title: 'Help & Information',
    links: [
      {
        text: 'Delivery Information',
        href: '/page/delivery-information',
      },
      {
        text: 'Refund Policy',
        href: '/page/refund',
      },
      {
        text: 'Cookies',
        href: '/page/cookies',
      },
      {
        text: 'Privacy Policy',
      },
      {
        text: 'Terms of Service',
      },
    ],
  },
];

export const socialData = [
  {
    Icon: FaInstagram,
    href: 'https://www.instagram.com/craftypartybox/',
  },
  {
    Icon: FaTwitter,
    href: 'https://twitter.com/craftypartybox',
  },
  {
    Icon: FaFacebookF,
    href: 'https://www.facebook.com/craftypartybox',
  },
  {
    Icon: FaPinterest,
    href: 'https://www.pinterest.co.uk/craftypartybox/',
  },
];

export default data;
