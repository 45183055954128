import React, { ReactElement } from 'react';
import Link from 'next/link';
import PageWidth from 'styles/components/PageWidth';
import data, { socialData } from './data';

const Footer = (): ReactElement => (
  <div className="w-full bg-blue text-white pt-10">
    <PageWidth>
      <div className="grid md:grid-cols-3">
        {data.map(({ title, links }) => (
          <div className="pb-10" key={title}>
            <h5 className="text-lg mb-4 font-bold uppercase">{title}</h5>

            {links.map(({ text, href = '' }) => (
              <Link key={text} href={href} passHref>
                <a href={href} className="mb-2 block hover:text-main">
                  {text}
                </a>
              </Link>
            ))}
          </div>
        ))}

        <div className="pb-10">
          <h5 className="text-lg mb-4 font-bold uppercase">Follow Us</h5>

          <div className="flex">
            {socialData.map(({ Icon, href }) => (
              <a
                href={href}
                key={href}
                className="mb-2 block  mr-3"
                target="_blank"
              >
                <Icon
                  size={36}
                  className="fa-icon fill-current text-white hover:text-main"
                />
              </a>
            ))}
          </div>
        </div>
      </div>
    </PageWidth>
  </div>
);

export default Footer;
